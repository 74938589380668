<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="currentColor"
  >
    <path d="M1.1472 15.8969C1.58343 14.8496 2.21647 13.8984 3.01108 13.0961C3.80326 12.2915 4.74168 11.65 5.77451 11.207C5.78376 11.2023 5.79301 11.2 5.80226 11.1953C4.36158 10.1406 3.42501 8.42266 3.42501 6.48438C3.42501 3.27344 5.99189 0.671875 9.16001 0.671875C12.3281 0.671875 14.895 3.27344 14.895 6.48438C14.895 8.42266 13.9585 10.1406 12.5178 11.1977C12.527 11.2023 12.5363 11.2047 12.5455 11.2094C13.5815 11.6523 14.5111 12.2875 15.309 13.0984C16.1028 13.9013 16.7358 14.8524 17.1728 15.8992C17.6022 16.924 17.8338 18.0228 17.855 19.1359C17.8556 19.161 17.8513 19.1858 17.8423 19.2091C17.8333 19.2324 17.8197 19.2537 17.8025 19.2716C17.7853 19.2895 17.7646 19.3037 17.7419 19.3134C17.7191 19.3231 17.6947 19.3281 17.67 19.3281H16.2825C16.1808 19.3281 16.0998 19.2461 16.0975 19.1453C16.0513 17.3359 15.3344 15.6414 14.0671 14.357C12.756 13.0281 11.0146 12.2969 9.16001 12.2969C7.30539 12.2969 5.56408 13.0281 4.25289 14.357C2.98564 15.6414 2.26876 17.3359 2.22251 19.1453C2.2202 19.2484 2.13926 19.3281 2.03751 19.3281H0.650013C0.625324 19.3281 0.600883 19.3231 0.578131 19.3134C0.555378 19.3037 0.534775 19.2895 0.517534 19.2716C0.500294 19.2537 0.486765 19.2324 0.477745 19.2091C0.468725 19.1858 0.464396 19.161 0.465012 19.1359C0.488138 18.0156 0.717075 16.9258 1.1472 15.8969ZM9.16001 10.5156C10.2215 10.5156 11.2205 10.0961 11.972 9.33438C12.7236 8.57266 13.1375 7.56016 13.1375 6.48438C13.1375 5.40859 12.7236 4.39609 11.972 3.63437C11.2205 2.87266 10.2215 2.45312 9.16001 2.45312C8.09858 2.45312 7.09958 2.87266 6.34801 3.63437C5.59645 4.39609 5.18251 5.40859 5.18251 6.48438C5.18251 7.56016 5.59645 8.57266 6.34801 9.33438C7.09958 10.0961 8.09858 10.5156 9.16001 10.5156Z" />
  </svg>
</template>
